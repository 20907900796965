const state = () => ({
  form: {},
})

const actions = {
  setComparadorForm({ commit }, form) {
    commit('setForm', form)
  },
}

const mutations = {
  setForm(state, form) {
    state.form = form
  },
}
export default {
  namespaced: false,
  state,
  mutations,
  actions,
}
