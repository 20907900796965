<template>
  <v-snackbar bottom right :timeout="-1" v-model="snackbar">
    <v-row align="center">
      <v-col> {{ progressTitle }} </v-col>

      <v-col cols="auto">
        <v-btn icon color="error" @click="progress = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="completed">
        Listo!
        <v-icon right color="success">mdi-check</v-icon>
      </v-col>
      <v-col v-else-if="indeterminate">
        <v-progress-linear indeterminate color="primary" size="100" width="10" />
      </v-col>
      <v-col v-else>
        <v-progress-linear :value="progress" height="25">
          {{ progress }}
          {{ Number.isInteger(progress) ? '%' : '' }}
        </v-progress-linear>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  name: 'WebsocketProgressBar',
  data() {
    return {
      progressTitle: null,
      progress: null,
      indeterminate: false,
      completed: false,
    }
  },
  computed: {
    snackbar() {
      return this.progress !== null
    },
  },
  mounted() {
    this.$options.sockets.onmessage = ({ data: response }) => {
      response = JSON.parse(response)
      const { type } = response
      if (type !== 'progress') return
      const { data } = response
      if (data.stop) {
        this.onStop()
      } else {
        const { title, progress, indeterminate = false, completed = false } = data
        this.progressTitle = title || this.progressTitle
        this.progress = progress
        this.indeterminate = indeterminate
        this.completed = completed
      }
    }
  },
  methods: {
    onStop() {
      this.progressTitle = null
      this.progress = null
    },
  },
}
</script>
